export const logo = ['608 134', `
  <title>Ecolive</title>
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="145" viewBox="0 0 266.991 177.037">
  <g id="_1080-1080" data-name="1080-1080" transform="translate(-11.58 -190.01)">
    <circle id="Ellipse_2" data-name="Ellipse 2" cx="88.518" cy="88.518" r="88.518" transform="translate(11.58 190.01)" fill="#3663f5"/>
    <circle id="Ellipse_3" data-name="Ellipse 3" cx="88.518" cy="88.518" r="88.518" transform="translate(101.534 190.01)" fill="#d4d4d4"/>
    <path id="Path_115" data-name="Path 115" d="M454.648,314.8a88.462,88.462,0,0,1-43.543,76.252,88.55,88.55,0,0,1,0-152.51A88.468,88.468,0,0,1,454.648,314.8Z" transform="translate(-266.031 -36.266)" fill="#4fb84a"/>
    <path id="Path_116" data-name="Path 116" d="M454.648,314.8a88.462,88.462,0,0,1-43.543,76.252,88.55,88.55,0,0,1,0-152.51A88.468,88.468,0,0,1,454.648,314.8Z" transform="translate(-266.031 -36.266)" fill="#3663f5"/>
    <text id="eco-live" transform="translate(105.398 286.895)" fill="#fff" font-size="37" font-family="SegoeUI-BoldItalic, Segoe UI" font-weight="700" font-style="italic"><tspan x="0" y="0">eco-li</tspan><tspan y="0" letter-spacing="-0.02em">v</tspan><tspan y="0">e</tspan></text>
  </g>
</svg>
`]
