import { combineReducers } from 'redux';
import adminReducer from './reducers/adminReducer';
import userReducer from './reducers/userReducer'
import shopReducer from './reducers/shopReducer'
import vehicleReducer from './reducers/vehicleReducer'



const reducers = combineReducers({
  admin: adminReducer,
  user: userReducer,
  shop: shopReducer,
  vehicle: vehicleReducer

});


export default reducers;
