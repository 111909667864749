// import { createStore } from 'redux'

// const initialState = {
//   sidebarShow: 'responsive'
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return {...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store




import { createStore, applyMiddleware } from 'redux'
// import { connectRouter } from 'connected-react-router';


import reducers from 'src/redux/indexReducer';
import thunk from 'redux-thunk';

// const initialState = {
//   sidebarShow: 'responsive'
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

// export const store = createStore(
//   // ,
//   // connectRouter(changeState)(reducers),
//   reducers,
//   {},
//   applyMiddleware(thunk)


// );

export const store = createStore(
  // ,
  // changeState
  // connectRouter(changeState)(reducers),
  reducers,
  {},
  applyMiddleware(thunk),

  // function


)



export default store
