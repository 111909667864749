
const reducer = (state = 0, action) => {
  switch (action.type) {

    case "GET_SHOP_CATEGORY_LIST":
      return { "getShopCategoryList": action.payload };

    case "ADD_NEW_SHOP_CATEGORY":
      return { "addNewShopCategory": action.payload };

    case "CHANGE_STATUS_SHOP_CATEGORY":
      return { "changeStatusShopCategory": action.payload };

    case "VIEW_SHOP_CATEGORY":
      return { "viewShopCategory": action.payload };

    case "UPDATE_SHOP_CATEGORY":
      return { "updateShopCategory": action.payload };

    case "DELETE_SHOP_CATEGORY":
      return { "deleteShopCategory": action.payload };

    case "GET_SHOP_SUB_CATEGORY_LIST":
      return { "getShopSubCategoryList": action.payload };

    case "CHANGE_STATUS_SHOP_SUB_CATEGORY":
      return { "changeStatusShopSubCategory": action.payload };

    case "DELETE_SHOP_SUB_CATEGORY":
      return { "deleteShopSubCategory": action.payload };

    case "ADD_NEW_SHOP_SUB_CATEGORY":
      return { "addNewShopSubCategory": action.payload };

    case "GET_PRODUCT_ATTRIBUTE_LIST":
      return { "getProductAttributeList": action.payload };

    case "GET_SHOP_CATEGORY_WITH_SUB_CATEGORY":
      return { "getShopCategoryWithSubCategory": action.payload };

    case "MAKE_MORE_ATTRIBUTE":
      return { "makeMoreAttribute": action.payload };

    case "RESPONSE_ERROR":
      return { "responseError": action.payload };

    case "API_ERROR":
      return { "apiError": action.payload };

    default:
      return state

    // Switch ends here===============================
  }

}

export default reducer;
