
const reducer = (state = 0, action) => {
  switch (action.type) {

    case "GET_VEHICLE_CATEGORY_LIST":
      return { "getVehicleCategoryList": action.payload };

    case "ADD_NEW_VEHICLE_CATEGORY":
      return { "addNewVehicleCategory": action.payload };

    case "CHANGE_STATUS_VEHICLE_CATEGORY":
      return { "changeStatusVehicleCategory": action.payload };

    case "DELETE_VEHICLE_CATEGORY":
      return { "deleteVehicleCategory": action.payload };

    case "RESPONSE_ERROR":
      return { "responseError": action.payload };

    case "API_ERROR":
      return { "apiError": action.payload };

    default:
      return state

    // Switch ends here===============================
  }

}

export default reducer;
