
const reducer = (state = 0, action) => {
  switch (action.type) {

    case "ADMIN_PROFILE":
      return { "adminProfile": action.payload };

    case "ADMIN_LOGIN":
      return { "adminLogin": action.payload };

    case "SEND_FORGET_PASSWORD_LINK":
      return { "sendForgetPasswordLink": action.payload };

    case "VERIFY_RESET_PASSWORD_TOKEN":
      return { "verifyResetPasswordToken": action.payload };

    case "CREATE_NEW_PASSWORD":
      return { "createNewPassword": action.payload };

    case "RESPONSE_ERROR":
      return { "responseError": action.payload };

    case "API_ERROR":
      return { "apiError": action.payload };

    default:
      return state

    // Switch ends here===============================
  }

}

export default reducer;
